// npm
import React, { memo } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Box, Container, ContainerProps, Grid, styled } from '@mui/material'
// src
import Flex from '@atoms/layout/Flex'
import SectionText, { SectionTextProps } from '@molecules/text/SectionText'
import { SectionTextVariants } from 'constants/section-text-variants'
import { createImageSectionTextDefaults } from 'helpers/createImageSectionTextDefaults'
import Stamp from 'images/stamps/bespoke-kitchens.svg'

interface ContentComponentProps {
  isContentComponent?: boolean
  altLayout?: boolean
}

interface Props extends SectionTextVariants, ContentComponentProps {
  image: IGatsbyImageData
  keyId: string
  subtitle: string
  mainHeading: string
  text: string
  button?: Queries.DatoCmsButton
  imageStamp?: boolean
  imagePlacement: string
  textContainerProps: ContainerProps
}

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    maxWidth: theme.breakpoints.values.md,
  },
}))

const TextContainer = styled(Container)(({ theme }) => ({
  padding: '0',
  [theme.breakpoints.down('sm')]: {
    padding: '0',
  },
  [theme.breakpoints.only('sm')]: {
    padding: '0',
  },
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0',
  },
}))

const StyledStamp = styled(Stamp)({
  width: '176px',
  position: 'absolute',
  top: '0',
  right: '0',
  zIndex: 10,
  transform: 'translate3d(50%, -50%, 0)',
})

const Image = styled(GatsbyImage, {
  shouldForwardProp: (prop) =>
    prop !== 'isContentComponent' && prop !== 'altLayout',
})<ContentComponentProps>(({ isContentComponent, altLayout, theme }) => ({
  boxShadow: `-750px -900px 0 600px ${theme.palette.primary.light}`,
  [theme.breakpoints.up('sm')]: {
    boxShadow: `-850px -900px 0 600px ${theme.palette.primary.light}`,
  },
  [theme.breakpoints.up('lg')]: {
    boxShadow: `1220px -1100px 0 1000px ${theme.palette.primary.light}`,
  },

  ...(isContentComponent && {
    boxShadow: `-670px -670px 0 600px ${theme.palette.primary.light}`,
    [theme.breakpoints.up('sm')]: {
      boxShadow: `-700px -700px 0 600px ${theme.palette.primary.light}`,
    },
    [theme.breakpoints.up('lg')]: {
      boxShadow: `1030px -1030px 0 1000px ${theme.palette.primary.light}`,
    },

    ...(altLayout && {
      [theme.breakpoints.up('lg')]: {
        boxShadow: `-1030px -1030px 0 1000px ${theme.palette.primary.light}`,
      },
    }),
  }),
}))

const ContentSectionTextImage = ({
  image,
  sectionTextVariant,
  keyId,
  subtitle,
  mainHeading,
  button,
  text,
  imageStamp,
  isContentComponent,
  imagePlacement,
  textContainerProps,
}: Props) => {
  const sectionTextProps: SectionTextProps = createImageSectionTextDefaults({
    sectionTextVariant,
    keyId,
    mainHeading,
    subtitle,
    text,
    button,
  })

  if (isContentComponent) {
    // @ts-ignore
    sectionTextProps.mainHeadingProps.typographyProps.component = 'h2'
    sectionTextProps.mainHeadingProps.typographyProps.variant = 'h4'
  }

  const altLayout = imagePlacement === 'left' ? true : false

  return (
    <Box
      pt={
        isContentComponent
          ? { xs: 2.5, sm: 3 }
          : { xs: imageStamp ? 2 : 7, lg: 15 }
      }
      pb={isContentComponent ? 3 : { xs: 6, lg: 13 }}
      sx={{
        position: 'relative',
        backgroundColor: 'white',
        overflow: 'hidden',
      }}
    >
      <StyledContainer maxWidth="xl" sx={{ padding: 0 }}>
        <Grid container spacing={isContentComponent ? 4 : 2}>
          <Grid
            item
            lg={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              order: {
                xs: 2,
                lg: altLayout ? 2 : 1,
              },
              mt: {
                xs: isContentComponent ? 0 : 3,
                lg: 0,
              },
            }}
          >
            {textContainerProps ? (
              <TextContainer {...textContainerProps}>
                <SectionText {...sectionTextProps} />
              </TextContainer>
            ) : (
              <div>
                <SectionText {...sectionTextProps} />
              </div>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              order: {
                xs: 1,
                lg: altLayout ? 1 : 2,
              },
            }}
          >
            {imageStamp ? (
              <Flex center fullWidth>
                <Box
                  sx={{
                    float: 'left',
                    position: 'relative',
                    mt: {
                      xs: '80px',
                      lg: 0,
                    },
                  }}
                >
                  <StyledStamp />
                  <Image
                    image={image}
                    alt=""
                    altLayout={altLayout}
                    isContentComponent={isContentComponent}
                  />
                </Box>
              </Flex>
            ) : (
              <Image
                image={image}
                alt=""
                altLayout={altLayout}
                isContentComponent={isContentComponent}
              />
            )}
          </Grid>
        </Grid>
      </StyledContainer>
    </Box>
  )
}

export default memo(ContentSectionTextImage)
